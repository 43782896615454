<template>
  <div id="Record" class="wrap">
    <div class="formWrap">
      <h1 class="title">申請履歴</h1>
      <div class="recordWrap">
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
        <b-table
          show-empty
          :fields="heads"
          :items="showData"
          id="record"
          thead-tr-class="head"
          tbody-tr-class="line"
          table-class="icon-white"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:cell(isAdopted)="data">
            <span v-if="data.item.isAdopted">○</span>
          </template>
          <template v-slot:cell(app)="data">
            <b-link class="recordLink" :to="'/application/'+data.item.apptypeId">申請</b-link>
          </template>
          <template v-slot:cell(report)="data">
            <b-link v-if="data.item.isAdopted && data.item.doneReport" class="recordLink"
              :to="'/report/'+data.item.reptypeId">報告</b-link>
            <span v-if="data.item.isAdopted && !data.item.doneReport">未報告</span>
          </template>
          <template v-slot:empty="scope">
            <p class="none-data">申請履歴はありません。</p>
          </template>
        </b-table>
      </div>
    </div>
    <div class="btnWrap">
      <b-button
        class="btn back bold"
        @click="$router.push({ name: 'gasTop' })"
      >
        トップに戻る
      </b-button>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';

export default {
  name: 'Record',
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      heads: [
        {
          key: 'name',
          label: '助成種別',
          sortable: true,
          class: 'name',
        },
        {
          key: 'year',
          label: '年度',
          sortable: true,
          class: 'year',
        },
        {
          key: 'code',
          label: '申請番号',
          sortable: false,
          class: 'code',
        },
        {
          key: 'isAdopted',
          label: '採択',
          sortable: true,
          class: 'isAdopted',
        },
        {
          key: 'app',
          label: '申請',
          sortable: false,
          class: 'app',
        },
        {
          key: 'report',
          label: '報告',
          sortable: false,
          class: 'report',
        },
      ],
      showData: [],
    };
  },
  computed: {
    rows() {
      return this.showData.length;
    },
  },
  async created() {
    this.$store.dispatch('page/onLoading');
    const res = await api.send('/api/myRecord')
      .catch((error) => {
        console.log(error);
        return false;
      });
    if (res !== false) {
      this.showData = res.data.list;
    }
    console.log(this.showData);
    this.$store.dispatch('page/offLoading');
  },
};
</script>


<style scoped>
.title{
  color: #333333;
  font-weight: 300;
  font-size: 24px;
  margin: 0 100px;
  margin-top: 52px;
  border-bottom: solid 2px #A0A9D0;
  padding-left: 10px;
  padding-bottom: 7px;
}
.recordWrap{
  margin: 0 100px;
  margin-top: 63px;
}
.recordLink{
  color: #0A8EA7;
}
.btnWrap{
  margin:45px 0;
  text-align: center;
}
</style>
<style>
table thead .head{
  background-color: #6E6E6E;
  color:#FFFFFF;
  font-weight: 600;
  font-size: 16px;
  border:solid 2px #FFFFFF;
  box-sizing:border-box;
}
table thead .head th{
  border:solid 1px #CCCCCC;
  word-break: keep-all;
}
table tbody tr.line{
  border-bottom: solid 1px #CCCCCC;
}
table tbody tr td{
  vertical-align:middle !important;
}
.none-data{
  margin-bottom:1px;
  text-align: center;
}
.page-item.active .page-link {
  background-color: #122889;
  border-color: #122889;
  color: #FFFFFF !important;
}
td.name{
  word-break: break-all;
}
td.code{
  width: 245px;
}
td.year,
td.isAdopted,
td.app,
td.report{
  width: 132px;
}
</style>
